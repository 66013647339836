import styled from 'styled-components/macro';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { MediaQueries, Colors, Sizes } from 'environment';
import {
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	margin-bottom: 12rem;
	width: 100%;

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
		margin-top: 8rem;
		justify-content: center;
		align-items: flex-start;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		flex-direction: column-reverse;
	}

	@media ${MediaQueries.phone} {
		flex-direction: column-reverse;
		margin-bottom: 6rem;
		align-items: center;
		justify-content: center;
		padding: 0 2rem;

		& > img {
			width: 100%;
			height: 100%;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_COLUMN * 7 + DESKTOP_GRID_SPACING * 6}rem;
		margin-right: 12rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		margin-right: 5rem;
	}
`;

export const Title = styled.h1`
	color: #283f50;
	margin-bottom: 4rem;

	@media ${MediaQueries.tablet} {
		margin-top: 8rem;
	}

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-weight: 600;
		font-size: 3.6rem;
		text-align: center;
		line-height: 5rem;
		margin-top: 4rem;
		margin-bottom: 2rem;
	}
`;

export const Subtitle = styled.h3`
	font-family: 'Open Sans';
	font-weight: 600;
	margin-bottom: 4rem;

	@media ${MediaQueries.phone} {
		font-family: 'Comfortaa';
		font-weight: bold;
		text-align: center;
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;
	}
`;

export const Description = styled.p`
	margin-bottom: 8rem;
	color: ${Colors.william};

	@media ${MediaQueries.phone} {
		text-align: center;
		margin-bottom: 4rem;
	}
`;

export const Footer = styled.p`
	margin-top: 4rem;
	color: ${Colors.william};

	@media ${MediaQueries.phone} {
		margin-bottom: 2rem;
	}
`;

export const CallToAction = styled(OutboundLink)`
	border-bottom: 0.2rem transparent solid;
	color: ${Colors.green};
	cursor: pointer;
	display: inline-block;
	transition: border-color 0.2s;

	:last-of-type {
		margin-top: 0;
	}

	&:hover {
		border-color: ${Colors.green};
	}
`;
