import React from 'react';

import seo from 'data/seo.json';
import { PressContent } from 'components/Press';
import { SEO } from 'components/UI';

export default function Press() {
	return (
		<>
			<SEO
				title={seo.pressTitle}
				description={seo.pressDescription}
				ogTitle={seo.pressOgTitle}
				ogDescription={seo.pressOgDescription}
				ogImage={seo.pressOgImage}
			/>
			<PressContent />
		</>
	);
}
