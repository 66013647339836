import React from 'react';

import data from 'data/press.json';
import config from 'data/general.json';
import { useMediaQuery, useQueryContext } from 'hooks';
import { MediaQueries } from 'environment';
import { Button } from 'components/UI';

import {
	Container,
	Content,
	Title,
	Subtitle,
	Description,
	Footer,
	CallToAction
} from './PressContent.style';

export function PressContent() {
	const { files } = useQueryContext();
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);

	function handlePressKitDownload() {
		if (files.length) {
			const pressKitPath = files.filter(e => e.node.publicURL.indexOf(data.fileName) !== -1);
			const pressKitURL = `${config.siteUrl}${pressKitPath[0].node.publicURL}`;

			setTimeout(() => {
				const response = {
					file: pressKitURL
				};
				window.location.href = response.file;
			}, 1000);
		}
	}

	return (
		<Container>
			<Content>
				<Title>{data.title}</Title>
				<Subtitle>{data.subtitle}</Subtitle>
				<Description>{data.description}</Description>
				<Button
					name={data.cta}
					width={isTablet ? 46 : isPhone ? '100%' : 38}
					onClick={handlePressKitDownload}
				>
					{isPhone ? data.ctaPhone : data.cta}
				</Button>
				<Footer>
					{data.footer.startDetails}&nbsp;
					<CallToAction href={`mailto: ${data.footer.email}`} rel="nofollow noreferrer">
						{data.footer.email}
					</CallToAction>
					&nbsp;{data.footer.endDetails}
				</Footer>
			</Content>
			<img src="/images/press.svg" alt={data.icon.src} />
		</Container>
	);
}
